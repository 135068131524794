import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>
                    Curs Intensiu Presencial Mossos d'Esquadra 2024/25 (Dissabtes 8:00 - 14:00 h)
                </Title>
                <Text>
                    Inici al setembre!
                    <br />
                    <br />
                    Us presentem el nou curs presencial de preparació per l'accés a Mossos
                    d'Esquadra 2024/25 en format intensiu.
                    <br />
                    <br />
                    Els dissabtes de 8.00 a 14.00 h
                    <br />
                    <br />
                    Ara ja pots reservar la teva plaça i reforçar els teus coneixements aquest estiu
                    amb el CAMPUS online.
                    <br />
                    <br />
                    A iOpos tenim la nostra pròpia metodologia i any rere any obtenim els millors
                    resultats.
                    <br />
                    <br />
                    Tots els alumnes inicien i finalitzen junts el curs, això ens permet que
                    assoleixen tots els continguts de la manera més eficient.
                    <br />
                    <br />
                    La formació consta de:
                    <br />
                    <br />
                    -Accés a un campus online amb tota l'actualitat setmanal en forma de notícies,
                    test per posar-vos a prova i vídeo resum setmanal.
                    <br />
                    <br />
                    -Formació didàctica i simulacres.
                    <br />
                    <br />
                    -30 hores de classes presencials amb explicacions dels conceptes més
                    susceptibles de sortir en examen tant a nivell teòric i de psicotècnics
                    aptitudinals.
                    <br />
                    <br />
                    -Accés a un grup exclusiu de WhatsApp amb reptes setmanals.
                    <br />
                    <br />
                    -Contacte directe amb la docent que imparteix el curs per resoldre qualsevol
                    dubta.
                    <br />
                    <br />
                    El primer dia de curs us donarem la nostra guia de Mossos d'Esquadra en paper i
                    un dossier de psicotècnics confeccionat pel nostre equip de psicòlegs.
                    <br />
                    <br />
                    Inici 14 de setembre de 8.00 a 14.00 h
                    <br />
                    <br />
                    -21 de setembre de 8.00 a 14.00 h
                    <br />
                    <br />
                    -28 de setembre de 8.00 a 14.00 h
                    <br />
                    <br />
                    -5 d'octubre de 8.00 a 14.00 h
                    <br />
                    <br />
                    Fi de curs 12 d'octubre de 8.00 a 14.00 h
                    <br />
                    <br />
                    El curs es realitza al c/Martí Codolar núm. 18, Hospitalet de Llobregat.
                    <br />
                    <br />
                    Les places són limitades!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/R_9CjLJKROM"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
